<template>
  <div>
    <window-header></window-header>
    <nav-form :onlyIcons="true"></nav-form>
    <section>
      <article style="height: 182px; width:500px">
        <field
          name="representante_id"
          widget="m2o"
          dbAdapter="representante"
          label="Representante"
          searchable
          width="200px"
          style="top:4px; left:4px;"
          required
        />
        <field
          name="fecha"
          type="date"
          label="Fecha"
          width="100px"
          style="top:44px; left:4px;"
        />
        <field
          name="cliente_id"
          widget="m2o"
          dbAdapter="cliente"
          label="Cliente"
          labelProp="nombre_comercial"
          searchable
          width="200px"
          style="top:84px; left:4px;"
          required
        />
        <field
          name="direccion"
          label="Dirección"
          width="200px"
          style="top:124px; left:4px;"
        />

        <field
          name="comentarios"
          widget="text"
          label="Comentarios"
          width="272px"
          height="80px"
          style="top:10px; left:218px;"
        />
        <field
          name="documentos"
          :max="6"
          widget="files"
          label="Adjuntos"
          style="top:108px; left:218px;"
        />
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function() {
    return {
      title: "Visitas",
      dbAdapter: "visita",
      primary: "id"
    };
  }
};
</script>
